<template lang="pug">
  article.cateogry._min-h-screen
    category-body(:uid="$route.params.category")
</template>

<script>
import CategoryBody from '@/components/CategoryBody'
export default {
  name: 'Category',
  components: { CategoryBody },
  computed: {
    title () {
      const cats = this.$store.state.categories
      const cat = cats.find(doc => doc.uid === this.$route.params.category)
      return cat ? this.$prismic.asText(cat.data.name)
        : cats.length ? 'Not Found'
          : false
    }
  },
  methods: {
    setBreadcrumb () {
      if (this.title) {
        this.$store.commit('setBreadcrumb', [['Shop', { name: 'shop' }], [this.title, this.$route.path]])
      }
    }
  },
  created () {
    this.$store.dispatch('getCategories')
    this.setBreadcrumb()
  },
  watch: {
    title () {
      this.setBreadcrumb()
    }
  }
}
</script>

<style>
</style>
