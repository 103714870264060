<template lang="pug">
  article.shop._min-h-screen
    template(v-if="doc")
      slice-body(:slices="doc.data.body1")
  //- .shop._font-mono
    //- shop categories
    section._flex._flex-wrap(v-for="category in catalog", :id="category.id")
      //- intro
      header._w-full._bg-black._text-white._p-8._text-left._flex._pb-32
        h2._w-1x2 {{ category.title }}
        prismic-rich-text._w-1x2(:field="category.primary.description")
      //- items
      //- loop through products by their variants, "row by row" (e.g. 75ml, 25ml, ...)
      template(v-for="(n, index) in rowCount(category)")
        //- loop through each product, insert product's variant if exists at that index:
        template(v-for="item in category.items")
          //- variant has shopify data ?
          template(v-if="item.shopify_product")
            //- render
            article._w-1x2.lg__w-1x3.xl__w-1x4(v-if="item.shopify_product.variants[index]")
              router-link._block._relative._pb-ar-5x6(:to="{name: 'shop__product__item', params: {slug: item.uid, variantTitle: item.shopify_product.variants[index].title}}")

                header._absolute._z-10._text-left._bottom-0._left-0._w-full._p-8._text-white._flex
                  ._flex-1._truncate
                    h6._inline
                      span(v-html="text(item, 'title')")
                    div._inline._lowercase(v-html="text(item, 'flavor')")
                  div._ml-4
                    span(v-if="Number(item.shopify_product.variants[index].price) > 0", v-html="price(item.shopify_product.variants[index].price)")
                    span(v-else) Coming Soon

                  //- image
                  //- figure.is-overlay(v-if="item.shopify_product.variants[index].image", v-lazy:background-image="bgImg(item.shopify_product.variants[index].image.src)")
                  //- h1
                  //-   span(v-html="text(item, 'title')")
                  //-   |
                  //-   span {{item.shopify_product.variants[index].title}}
                  //- h2(v-html="text(item, 'flavor')")
                  //- h2(v-if="Number(item.shopify_product.variants[index].price) > 0", v-html="price(item.shopify_product.variants[index].price)")
                  //- h2(v-else) Coming Soon
                //- image
                resp-img(:bg="true", :image="item.shopify_product.variants[index].image")
    //- img(v-if="catalog.length > 0", src @error="$emit('loaded')").is-hidden
</template>

<script>
import Vue from 'vue'
import RespImg from '@/components/RespImg'
import { mapState, mapGetters, mapActions } from 'vuex'
import SliceBody from '@/components/SliceBody'
// import Prismic from 'prismic-javascript'
export default {
  name: 'Shop',
  components: { RespImg, SliceBody },
  // props: ['currency'],
  data () {
    return {
      text: Vue.$text
      // price: Vue.$price
    }
  },
  computed: {
    ...mapState({
      doc: 'shop'
    }),
    ...mapGetters(['catalog', 'price'])
  },
  methods: {
    ...mapActions(['getProducts']),
    rowCount (category) {
      // loop through products and find which has the most variants, set as max "row" count
      let highest = 0
      for (var i = 0; i < category.items.length; i++) {
        const shopifyData = category.items[i].shopify_product
        const variants = shopifyData ? shopifyData.variants : []
        if (variants.length > highest) highest = variants.length
      }
      return highest
    },
    bgImg (url) {
      const ratio = 482 / 800
      const h = window.innerHeight * ratio
      return Vue.$thumbSrc(url, h, 'png')
    }
    // getProducts () {
    //   // Get the Categories
    //   Prismic.getApi(Vue.config.PRISMIC.API).then((api) => {
    //     return api.query(Prismic.Predicates.at('document.type', 'shop'))
    //   }).then((response) => {
    //     // Save to App.vue
    //     this.$emit('update', 'shop', response.results[0])
    //     // Collect the Product IDs
    //     const categories = response.results[0].data.body
    //     const itemIDs = []
    //     for (var i = 0; i < categories.length; i++) {
    //       if (categories[i].slice_type === 'category') {
    //         const items = categories[i].items
    //         for (var p = 0; p < items.length; p++) {
    //           itemIDs.push(items[p].product.id)
    //         };
    //       }
    //     }
    //     // Get the Products from the IDs
    //     Prismic.getApi(Vue.config.PRISMIC.API).then((api) => {
    //       return api.query(
    //         Prismic.Predicates.in('document.id', itemIDs),
    //         { pageSize: 100 }
    //       )
    //     }).then((response) => {
    //       // Save to App.vue
    //       this.$emit('update', 'products', response.results)
    //     }, (err) => {
    //       console.log('Error: Get Products failed', err)
    //     })
    //   }, (err) => {
    //     console.log('Error: Get Shop failed', err)
    //   })
    // }
  },
  created () {
    this.$store.dispatch('getCategories')
    this.$store.commit('setBreadcrumb', [['Shop', { name: 'shop' }]])
  }
}
</script>

<style lang="scss" scoped>
@import '../style/variables';

// .shop{

//   .columns{
//     margin:0;
//     border-bottom-style: solid;
//   }

//   .column{
//     border-right-style:solid;
//     border-bottom-style: solid;
//     margin-bottom: -1px;
//     @include until ($tablet) {
//       &:nth-child(even) {
//         border-right: none;
//       }
//     }
//     @include tablet {
//       &:nth-child(3n) {
//         border-right:none;
//       }
//     }
//   }

//   article{
//     position: relative;
//     height:100vh; // calc(100vh - #{$nav-h} * 2);

//     header{
//       // background
//       > .is-overlay{
//         background-position:center center;
//         background-repeat:no-repeat;
//         background-size:auto (480/800)*100%;
//         line-height: 1.2;
//       }
//       // title
//       > div{
//         position: absolute;
//         bottom:6.25%; left:0;
//         width:100%;
//         padding:0 $pad;
//       }
//     }

//     @include from($tablet) {
//       @media (max-aspect-ratio:1100/900) {
//         height:50vh;
//         header{
//           background-position:center 38%;
//         }
//       }
//     }
//   }
// }

// @include mobile () {
//   .shop {
//     // margin-top: -$nav-h--mobile;
//     // // spacer
//     // &:before{
//     //   content:'';
//     //   display:block;
//     //   height: calc(#{$nav-h--mobile} * 2);
//     //   border-bottom-style: solid;
//     // }
//     article{
//       @media (orientation:portrait) {
//         height:calc((100vh - #{$nav-h--mobile} * 2) / 2); // 50vh;
//       }

//       header{
//         background-position:center 30%;
//         font-size:$fs-s;
//         > div{
//           padding:0 $pad/2;
//         }
//       }
//     }
//   }
// }
</style>
